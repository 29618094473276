import { Comment, Text } from 'vue';
import type { VNode } from 'vue';

export const stripEmpty = (arr: VNode[]): VNode[] => {
  // TODO: check this: https://github.com/element-plus/element-plus/pull/14354/files and https://github.com/element-plus/element-plus/pull/15036/files
  return arr.filter(o => o.type !== Comment && (o.type !== Text || (typeof o.children === 'string' && o.children.trim() !== '')));
};

export default {
  stripEmpty,
};
