import { useAuthStore } from '@/stores/auth';
import { useSystemStore } from '@/stores/system';
import { type ProjectData } from '@/modules/settings/models/ProjectData';
import { getProjectById } from '@/modules/settings/service';
import { actionsProjectInfo } from '@/modules/settings/store/actions/projectInfo';
import { actionsTagGroups } from '@/modules/settings/store/actions/tagGroups';
import { actionsXrayIntegration } from '@/modules/settings/store/actions/xrayIntegration';

const initialState: ProjectData = {
  projectInfo: {
    projectId: 'no-project-available',
    projectName: '',
    owner: '',
  },
};

const state = reactive(initialState);

const project = {
  state: readonly(state),

  init: async (): Promise<void> =>
    new Promise((resolve, reject) => {
      watchEffect(async () => {
        const { selectedProjectId } = useSystemStore();
        if (!selectedProjectId) return reject('No selected project.'); // TODO: i18n

        const auth = useAuthStore();
        if (!auth.isLoggedIn) reject('Not logged in.'); // TODO: i18n

        try {
          const projectData = await getProjectById(selectedProjectId);
          Object.keys(projectData).forEach(key => {
            state[key] = projectData[key];
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    }),

  action: {
    projectInfo: actionsProjectInfo(state),
    tagGroups: actionsTagGroups(state),
    xrayIntegration: actionsXrayIntegration(state),
  },
};

export default project;
