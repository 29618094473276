import { useResizeObserver } from '@vueuse/core';
import variables from '@/assets/theme/variables.module.scss';

const cockpitPromiseResolver = ref<(value: void | PromiseLike<void>) => void>();

const initPromise = () =>
  new Promise(resolve => {
    cockpitPromiseResolver.value = resolve;
  });

export const isBreadcrumbVisible = ref(false);
export const isSidebarExpanded = ref(false);
export const isCockpitVisible = ref(false);
export const cockpitHeight = ref(0);
export const cockpitPromise = ref(initPromise());
export const cockpitHasCustomHeadline = ref(false);
export const isTextWrapped = ref(false);

export const resetPromise = () => {
  cockpitPromiseResolver.value?.();
  cockpitPromise.value = initPromise();
};

export const breadcrumbHeight = toRef(() => (isBreadcrumbVisible.value ? Number(variables.breadcrumbHeightPx) : 10));

export const breadcrumbOffset = toRef(() => Number(variables.topbarHeightPx) + breadcrumbHeight.value);
export const breadcrumbOffsetCss = toRef(() => breadcrumbOffset.value + 'px');

export const cockpitOffset = toRef(() => breadcrumbOffset.value + cockpitHeight.value);
export const cockpitOffsetCss = toRef(() => cockpitOffset.value + 'px');

export const registerBreadcrumb = (isVisible: Ref<boolean>) => {
  watch(
    isVisible,
    v => {
      nextTick(() => {
        isBreadcrumbVisible.value = v;
      });
    },
    { immediate: true }
  );
};

export const registerCockpit = (cockpitRef: Ref<HTMLElement | null>, headlineRef: Ref<string | undefined>) => {
  onMounted(() => {
    if (!isCockpitVisible.value) {
      isCockpitVisible.value = true;

      watch(
        headlineRef,
        () => {
          cockpitHasCustomHeadline.value = !!headlineRef.value;
        },
        { immediate: true }
      );

      onBeforeUnmount(() => {
        isCockpitVisible.value = false;
        cockpitHasCustomHeadline.value = false;
        resetPromise();
      });

      useResizeObserver(cockpitRef, entries => {
        const entry = entries[0];
        const { height } = entry.contentRect;
        cockpitHeight.value = height;
        cockpitPromiseResolver.value?.();
      });
    }
  });
};

export const scrollIntoView = async (el: HTMLElement | null, options: { offsetTop?: number; onlyUp?: boolean } = {}) => {
  await cockpitPromise.value;
  await nextTick();

  if (!el) return;

  const { onlyUp = false, offsetTop = cockpitOffset.value } = options;

  const top = el.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offsetTop;

  if (!onlyUp || window.scrollY > top) {
    window.scrollTo({ top });
  }
};
