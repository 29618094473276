<template>
  <ElConfigProvider :locale>
    <div ref="observedEl" :class="{ cypress: isCypress }" style="height: 100%">
      <component :is="LayoutComponent" :key="layoutKey" v-slot="slotProps">
        <RouterView v-slot="{ Component }">
          <Transition name="fade" mode="out-in" appear>
            <div :key="contentKey + Component?.type['name'] + Component?.type['__name']">
              <component :is="Component" v-bind="slotProps" />
            </div>
          </Transition>
        </RouterView>
      </component>
    </div>
  </ElConfigProvider>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { ERROR } from '@route-types';
import { useResizeObserver } from '@vueuse/core';
import en from 'element-plus/dist/locale/en.mjs';
import { storeToRefs } from 'pinia';
import { isSidebarExpanded } from '@/composables/useLayout';
import { usePageTitle } from '@/composables/usePageTitle';
import DefaultLayout from '@/layouts/Default.vue';
import LoadingLayout from '@/layouts/Loading.vue';
import { i18n } from '@/plugins/vue-i18n';
import { useAuthStore } from '@/stores/auth';
import { mockApi } from '@/stores/storage';
import { useSystemStore } from '@/stores/system';
import { getAreaOfRoute, metas } from '@/utils/navigation';
import variables from '@/assets/theme/variables.module.scss';

const systemStore = useSystemStore();
const authStore = useAuthStore();
const { initComplete } = storeToRefs(systemStore);
const { isLoggedIn } = storeToRefs(authStore);
const route = useRoute();

usePageTitle(route, isLoggedIn);

const LayoutComponent = toRef<Component>(() => {
  const layout = route.meta?.layout || route.matched?.[0]?.meta?.layout || DefaultLayout;

  if (route.matched?.[0]?.name === ERROR) return layout;
  if (!route.name || !initComplete) return LoadingLayout;
  return layout;
});

const getSubNavigationName = () => {
  const name = route.name;
  if (name && metas[name].path[1] && metas[metas[name].path[1]].isSubNavigation) return metas[name].path[1];
  if (name && metas[name].path[2] && metas[metas[name].path[2]].isSubNavigation) return metas[name].path[2];
  return undefined;
};

const locale = en;
const layoutKey = toRef(() => (route.name && metas[route.name].refreshOnLanguageChange ? i18n.locale.value : '') + LayoutComponent.value.name);
const contentKey = toRef(() =>
  [
    mockApi.value,
    getAreaOfRoute(route),
    getSubNavigationName(),
    route.name && metas[route.name].refreshOnUrlChange ? JSON.stringify(route.params) : undefined,
  ].toString()
);

const observedEl = ref(null);
const breakpointMediumPx = Number(variables.breakpointMediumPx);
let lastWidth = 0;

useResizeObserver(observedEl, entries => {
  const entry = entries[0];
  const { width } = entry.contentRect;

  const isGettingSmaller = width - lastWidth < 0;

  if (isGettingSmaller && width < breakpointMediumPx) {
    isSidebarExpanded.value = false;
  } else if (!isGettingSmaller && width >= breakpointMediumPx) {
    isSidebarExpanded.value = true;
  }

  lastWidth = width;
});

const isCypress = toRef(() => !!window.Cypress);
</script>
