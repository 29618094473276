import axios, { type AxiosInstance } from 'axios';
import type { z } from 'zod';

/**
 * NOTE: This file is also used by SessionRefreshingWorker.ts and therefore may not have a dependency to useSystemStore
 * (because it cannot fetch the config.json and to avoid a circular depdendency)
 */
export const plainApiClientInstance: AxiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const postOAuth2Token = async <T extends z.ZodTypeAny>(
  zodType: T,
  cognitoUrl: string,
  queryParams: { client_id: string } & Record<string, string>
): Promise<z.infer<T>> => {
  const { data } = await plainApiClientInstance.post(`${cognitoUrl}/oauth2/token/`, null, {
    params: queryParams,
  });
  return zodType.parse(data);
};
