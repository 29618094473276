import { Config, RefreshTokenResponse, TokenResponse } from '@/models/Auth';
import { plainApiClientInstance, postOAuth2Token } from '@/services/AuthShared';
import { useSystemStore } from '@/stores/system';

export const postVerifyLogin = async (code: string, codeVerifier: string, currentUrl: string) => {
  const { cognitoUrl, clientId } = useSystemStore();
  return postOAuth2Token(TokenResponse, cognitoUrl, {
    client_id: clientId,
    grant_type: 'authorization_code',
    code,
    code_verifier: codeVerifier,
    redirect_uri: currentUrl,
  });
};

/** NOTE: The following function is duplicated inside SessionRefreshingWorker.ts. Apply changes to both! */
export const postRefresh = async (refreshToken: string) => {
  const { cognitoUrl, clientId } = useSystemStore();
  return postOAuth2Token(RefreshTokenResponse, cognitoUrl, {
    client_id: clientId,
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  });
};

export const getConfig = async () => Config.parse((await plainApiClientInstance.get(`${import.meta.env.BASE_URL}tenant/config.json`)).data);
