import type { ProjectData, ProjectInfo } from '@/modules/settings/models/ProjectData';
import { getProjectInfo, updateProjectInfo } from '@/modules/settings/service/projectInformation';

export const actionsProjectInfo = (project: ProjectData) => ({
  getProjectInfo: async () => {
    const { projectId } = project.projectInfo;
    const projectInfo = await getProjectInfo(projectId);
    project.projectInfo = projectInfo;
  },

  updateProjectInfo: async (payload: Partial<ProjectInfo>) => {
    const { projectId } = project.projectInfo;
    const projectInfo = await updateProjectInfo(projectId, { ...project.projectInfo, ...payload });
    project.projectInfo = projectInfo;
  },
});
