import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n';
import axios from 'axios';

const locale = 'en'; // TODO: detect from browser

// Fallback basic translations before i18n file is loaded ... (i.e. while loading, or in case of an error)
const messages = {
  en: {
    common: {
      loading: 'Loading',
    },
  },
};

export const i18nPlugin = createI18n({
  locale,
  fallbackLocale: 'en',
  messages: { [locale]: messages[locale] },
  legacy: false,
  globalInjection: true,
  warnHtmlInMessage: 'off',
  warnHtmlMessage: false,
});

const setI18nLanguage = (locale: 'en'): void => {
  if (i18nPlugin.mode === 'legacy') {
    i18nPlugin.global.locale = locale as any;
  } else {
    i18nPlugin.global.locale.value = locale;
  }

  axios.defaults.headers.common['Accept-Language'] = locale;
  // document.querySelector('html').setAttribute('lang', locale);

  // localStorage.setItem('languageEvent', `${locale};${this.sessionId}`); // TODO: change language in all tabs
};

const loadLocaleMessages = async (locale: string): Promise<void> => {
  // load locale messages with dynamic import
  const messages = await import(`@/locales/${locale}.json`);

  // set locale and locale message
  i18nPlugin.global.setLocaleMessage(locale, messages.default);

  return nextTick();
};

export const initI18n = async () => {
  await loadLocaleMessages(locale);
  setI18nLanguage(locale);
};

export const i18n = i18nPlugin.global;
