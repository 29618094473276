import { ERROR } from '@route-types';
import { ZodError } from 'zod';
import router, { routeHistory } from '@/router';

export class HandledError extends Error {
  public cause;
  constructor(cause: any) {
    if (cause instanceof HandledError) {
      cause = cause.cause;
      super(cause.message, { cause });
    } else if (cause instanceof Error) {
      super(cause.message, { cause });
    } else if (typeof cause === 'string') {
      super(cause);
    } else if (typeof cause === 'object' && cause && 'message' in cause) {
      super(cause.message, { cause });
    } else {
      super('Unknown Error', { cause });
    }
    this.cause = cause;
  }

  static WithRedirect(error: unknown) {
    errorHandler(error);
    return new HandledError(error);
  }
}

export const errorWrapper = async <T>(callback: () => T): Promise<T> => {
  try {
    return await callback();
  } catch (e) {
    throw HandledError.WithRedirect(e);
  }
};

export const serializeError = (error: unknown) => {
  const replaceErrors = (key, value) => {
    if (value instanceof Error) {
      const error = {};

      Object.getOwnPropertyNames(value).forEach(propName => {
        error[propName] = value[propName];
      });

      return error;
    }

    return value;
  };

  return JSON.stringify(error, replaceErrors);
};

const previousPage = () => {
  return routeHistory
    .filter(r => r.name !== ERROR)
    .reverse()
    .slice(1)
    .find(r => !r.meta?.isRedirect)?.fullPath;
};

export const errorHandler = (error: unknown) => {
  if (error instanceof HandledError) {
    // this error was already handled. no further action needed
    return;
  }
  console.error(error);

  const query = {
    traceId: undefined,
    message: 'Unexpected error occurred', // TODO: i18n
    redirectFrom: router.currentRoute?.value.query.redirectFrom || router.currentRoute?.value.redirectedFrom?.path || router.currentRoute?.value.path,
    previousPage: router.currentRoute?.value.query.previousPage || previousPage(),
  };

  if (error instanceof ZodError) {
    query.message = 'An error was detected while parsing the response'; // TODO: i18n
  } else if (error instanceof Error) {
    query.message = error.message;
    query.traceId = error?.['response']?.['data']?.['traceId'];
  }

  router.push({
    name: ERROR,
    force: true,
    query,
  });
};

window.onerror = (message, source, lineno, colno, error) => {
  if (typeof message === 'string' && message.includes('ResizeObserver')) return; // occurs occasionally in 3rd party library "ElementPlus" - ignore this for now ('ResizeObserver loop limit exceeded' / 'ResizeObserver loop completed with undelivered notifications.')
  // if (message === 'Script error.' && source === '' && lineno === 0 && colno === 0 && error === null) return; // occurs occasionally in 3rd party library "ElementPlus" on dynamic dropdown only on safari
  console.error(message, source, lineno, colno, error);
  router.push({
    name: ERROR,
    force: true,
    query: {
      message: typeof message === 'string' ? message : String(error),
      redirectFrom: router.currentRoute?.value.query.redirectFrom || router.currentRoute?.value.redirectedFrom?.path,
      previousPage: router.currentRoute?.value.query.previousPage || previousPage(),
    },
  });
};
