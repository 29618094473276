<template>
  <svg class="icon-grid" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4755_50420)">
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 2 21)" stroke="currentColor" />
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 2 13.6313)" stroke="currentColor" />
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 2 6.26318)" stroke="currentColor" />
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 9.36865 13.6313)" stroke="currentColor" />
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 9.36865 21)" stroke="currentColor" />
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 16.7368 13.6313)" stroke="currentColor" />
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 9.36865 6.26318)" stroke="currentColor" />
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 16.7368 21)" stroke="currentColor" />
      <rect x="0.5" y="-0.5" width="4.26316" height="4.26316" transform="matrix(1 0 0 -1 16.7368 6.26318)" stroke="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_4755_50420">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
