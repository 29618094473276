import { hasOwnProperty } from '@/utils/assertions';
import type { LocationQueryValue } from 'vue-router';

/** Query Parameter can either be string or array (or `null` if it's just a key without following `=`). This function strips out nulls and always returns an array */
export const normalizeQueryParam = (value: string | LocationQueryValue | LocationQueryValue[]): string[] =>
  value === null ? [] : Array.isArray(value) ? value.filter((item): item is string => item !== null) : [value];

export type RoutesToPayloadMap = Record<string, unknown | ((...args: unknown[]) => unknown)>;

export const getMatch = (routesToPayloadMap: RoutesToPayloadMap, url: string) => Object.entries(routesToPayloadMap).find(([route]) => url.match(route));

export const getParams = (route: string, url: string): string[] | undefined => {
  const m = url.match(route);
  return m?.slice(1, m.length);
};

export const toPayload = (routesToPayloadMap: RoutesToPayloadMap, url: string) => {
  const m = getMatch(routesToPayloadMap, url);
  if (!m) return;
  const [route, payload] = m;
  if (typeof payload === 'function') {
    const args = getParams(route, url);
    if (!args) return payload();
    return payload(...args);
  }
  return payload;
};

export const insertPlaceholders = <T extends Record<string, string | number>>(url: string, replacements: T) =>
  url.replace(/{(\w+)}/g, (placeholderWithDelimiters, placeholderWithoutDelimiters) => {
    if (!hasOwnProperty(replacements, placeholderWithoutDelimiters)) throw new Error('Placeholder could not be found: ' + placeholderWithDelimiters); // TODO: simplify this once a newer TypeScript version is released (which is supporting throw Expressions)
    return String(replacements[placeholderWithoutDelimiters]);
  });
