import { z } from 'zod';

export const Config = z.object({
  apiUrl: z.string(),
  roles: z.string().array(), // NOTE: We could use type ZodRoleEnum here, but for compatibility reasons we accept all strings and verify this later
  cognitoUrl: z.string(),
  clientId: z.string(),
  externalLinks: z.record(z.string(), z.string()),
});
export type Config = z.infer<typeof Config>;

export const TokenResponse = z.object({
  access_token: z.string(),
  refresh_token: z.string(),
  expires_in: z.number(),
});
export type TokenResponse = z.infer<typeof TokenResponse>;

export const RefreshTokenResponse = z.object({
  access_token: z.string(),
  expires_in: z.number(),
});
export type RefreshTokenResponse = z.infer<typeof RefreshTokenResponse>;

export const AccessTokenPayload = z.object({
  username: z.string(),
  preferred_username: z.string().optional(),
  prj: z.string().optional(),
});
export type AccessTokenPayload = z.infer<typeof AccessTokenPayload>;
