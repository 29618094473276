<template>
  <ElTooltip
    v-for="item in items"
    :key="item.name"
    :content="$t(`navigation.${item.name}`)"
    placement="right"
    :disabled="isSidebarExpanded"
    transition="none"
    :show-after="400"
    :hide-after="0"
  >
    <RouterLink :to="item" :class="{ active: isActive(item), expanded: isSidebarExpanded }" class="focus-shadow-inset">
      <component :is="item.icon" v-if="item.icon" />
      <FontAwesomeIcon v-else :icon="faSquare" size="lg" />
      <span class="regular-md">{{ $to(item.name, 'navigation.') }}</span>
    </RouterLink>
  </ElTooltip>
</template>

<script setup lang="ts">
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isSidebarExpanded } from '@/composables/useLayout';
import { metas, type NavigationItem } from '@/utils/navigation';

const route = useRoute();

defineProps<{ items: NavigationItem[] }>();

const isActive = (item: NavigationItem) => !!route.name && !!item.name && metas[route.name].path.includes(item.name);
</script>

<style lang="scss" scoped>
@use '@/assets/theme/variables';

a {
  text-decoration: none;
  padding: 10px 15px;
  color: var(--el-text-color-primary);
  background: var(--color-background);
  width: variables.$sidebar-mobile-width;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: var(--color-background-hover);
  }

  &.active {
    background-color: var(--color-background-light);
  }

  & > span {
    display: none;
    line-height: 0;
    color: var(--el-text-color-primary);
  }

  &.expanded {
    width: variables.$sidebar-width;

    display: flex;
    justify-content: flex-start;

    span {
      display: initial;
    }
  }
}
</style>
