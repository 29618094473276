<template>
  <svg class="icon-mode-dark" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4666_34017)">
      <path
        d="M8.89914 5.07994C8.89914 10.6869 13.4442 15.232 19.0512 15.232C20.0769 15.232 21.067 15.0804 22.0004 14.7975C20.7368 18.9662 16.8644 22 12.2832 22C6.67618 22 2.1311 17.4549 2.1311 11.8483C2.1311 7.26702 5.16555 3.3947 9.33398 2.1311C9.05108 3.06442 8.89914 4.05424 8.89914 5.07994Z"
        stroke="currentColor"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4666_34017">
        <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)" />
      </clipPath>
    </defs>
  </svg>
</template>
