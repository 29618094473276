import { cloneDeep } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { AccessTokenPayload } from '@/models/Auth';
import { ZodRoleEnum } from '@/models/user/RoleEnum';
import { useSystemStore } from '@/stores/system';
import { parseJwt } from '@/utils/auth';
import type { RoleEnum } from '@/models/user/RoleEnum';

const postAuthResolvers = {
  resolve: () => {},
};

export type State = {
  tokenPayload: AccessTokenPayload | null;
  roles: RoleEnum[];
  postAuthResolvers: { promise: Promise<void> };
};

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    tokenPayload: null,
    roles: [],
    postAuthResolvers: {
      promise: new Promise((resolve: (value: void) => void) => {
        postAuthResolvers.resolve = resolve;
      }),
    },
  }),
  getters: {
    isLoggedIn: state => state.tokenPayload !== null,
    fullName: state => (state.tokenPayload ? (state.tokenPayload.preferred_username ?? state.tokenPayload.username) : ''),
    availableProjectIds: state => state.tokenPayload?.prj?.split(',') ?? ['no-project-available'],
    currentTenantId: _ => 'default-tenant',
  },
  actions: {
    parseAccessToken(accessToken: string) {
      const payload = AccessTokenPayload.parse(parseJwt(accessToken));
      this.tokenPayload = cloneDeep(payload);
      const systemStore = useSystemStore();

      this.roles =
        [...(systemStore.config?.roles ?? []), ...[]] // TODO: receive additional roles from BE?
          .filter(roleName => ZodRoleEnum.safeParse(roleName).success)
          .map(roleName => roleName as RoleEnum) ?? [];

      const localSelectedProjectId = systemStore.receiveLocalSelectedProjectId();
      systemStore.setProjectId(localSelectedProjectId ?? this.availableProjectIds[0]);

      postAuthResolvers.resolve();
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
