import type { paths } from '@/@types/projects';

export const path = {
  projectById: '/projects/v1/{id}',
  projectInfo: '/projects/v1/{id}/info',
  tagGroup: '/projects/v1/{id}/tag-groups/{tagGroupId}',
  tagGroups: '/projects/v1/{id}/tag-groups',
  tagGroupsReorder: '/projects/v1/{id}/tag-groups/order',
  xrayIntegration: '/projects/v1/{id}/xray',
  triggerGitSyncRepo: '/projects/v1/{id}/git/sync-repo',
} as const satisfies Record<string, keyof paths>;
