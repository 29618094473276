<template>
  <svg height="100%" viewBox="0 0 30 30" width="100%">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.7286 12.1714L26.5035 14.0333C26.86 18.3498 24.7674 22.6965 20.7745 25.0013C15.2593 28.1853 8.18294 26.2891 4.99898 20.7743C1.81501 15.2599 3.71119 8.18272 9.22601 4.99913C14.2325 2.10822 20.5272 3.40545 24.0265 7.79638L21.2217 9.41574C18.6385 6.53201 14.3061 5.74801 10.8204 7.7605C6.82189 10.0691 5.45216 15.1817 7.76035 19.1799C10.0689 23.178 15.1812 24.5482 19.1801 22.2396C21.5872 20.8498 23.0408 18.4435 23.3127 15.8754L17.4573 19.2565L17.4554 19.2531C15.1102 20.607 12.1014 19.8007 10.7476 17.4555C9.39368 15.1111 10.2 12.1016 12.5452 10.7481C14.3639 9.69785 16.5822 9.94823 18.1181 11.2081L14.1373 13.5061C13.3122 13.9827 13.0293 15.0378 13.5055 15.8634C13.9821 16.6885 15.0373 16.971 15.8628 16.4948L22.9471 12.4048L25.7519 10.7851L28.7648 9.04564C28.758 9.02903 28.7509 9.01279 28.7437 8.99655C28.701 8.89874 28.6568 8.80093 28.6115 8.70425L28.573 8.62117C28.5262 8.52147 28.4778 8.42252 28.4287 8.32396L28.3876 8.24125C28.3377 8.14306 28.2864 8.0445 28.2342 7.94668L28.1923 7.86738C28.1259 7.74464 28.0583 7.62266 27.9884 7.50181C23.8467 0.327986 14.6744 -2.12976 7.50128 2.01192C0.327831 6.15323 -2.12953 15.3252 2.01177 22.499C6.15307 29.6721 15.325 32.1298 22.4993 27.9878C28.181 24.7071 30.9004 18.2709 29.7282 12.1711"
      fill="currentColor"
    />
  </svg>
</template>
