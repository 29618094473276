import { uriBase64Decode, uriBase64Encode } from '@/utils/string';

export const ACCESS_TOKEN = 'accessToken'; // NOTE: sync with index.html!
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRY_DATE = 'expiryDate';

export const generateCodeVerifier = () => {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec => ('0' + dec.toString(16)).slice(-2)).join('');
};

export const generateCodeChallengeFromVerifier = async (codeVerifier: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', data);
  return uriBase64Encode(String.fromCharCode(...new Uint8Array(hashBuffer))).split('=')[0];
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  if (!base64Url) throw new Error('Invalid token!');
  const jsonPayload = decodeURIComponent(
    uriBase64Decode(base64Url)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};
