import { stripEmpty } from '@/utils/slot';
import type { ComputedRef, Slot } from 'vue';

export default function useSlot(slot: Slot | undefined, mandatoryRef: ComputedRef | null = null) {
  const visibleSlotEntries = computed(() => {
    return (slot && stripEmpty(slot())) || [];
  });

  const isSlotVisible = computed(() => {
    return visibleSlotEntries.value.length > 0;
  });

  const isMandatoryOrSlotVisible = computed(() => {
    return mandatoryRef?.value || isSlotVisible.value;
  });

  return {
    visibleSlotEntries,
    isSlotVisible,
    isMandatoryOrSlotVisible,
  };
}
