import { i18n } from '@/plugins/vue-i18n';
import { translateOptional } from '@/plugins/vue-i18n-utils';
import { getAreaOfRoute } from '@/utils/navigation';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

export const usePageTitle = (route: RouteLocationNormalizedLoaded, isLoggedIn: MaybeRefOrGetter<boolean>) => {
  watch(
    route,
    () => {
      if (toValue(isLoggedIn)) {
        window.document.title = `${translateOptional(i18n, getAreaOfRoute(route) ?? '', 'navigation.')} - exact`;
      } else {
        window.document.title = 'exact';
      }
    },
    { deep: true, immediate: true }
  );
};
