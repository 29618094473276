import { errorWrapper } from '@/errorHandler';
import { useSystemStore } from '@/stores/system';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { z } from 'zod';

let requestCount = 0;
let lastRequestAbortController: AbortController;

export const captureAbortController = <T>(callback: () => T, captureCallback: (controller: AbortController) => void): T => {
  const currentCount = requestCount;
  const previousAbortController = lastRequestAbortController;
  const r = callback();
  if (requestCount === currentCount + 1 && lastRequestAbortController !== previousAbortController) captureCallback(lastRequestAbortController);
  return r;
};

const mergeConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  requestCount++;
  lastRequestAbortController = new AbortController();

  const append: AxiosRequestConfig = { signal: lastRequestAbortController.signal };
  if (!config) return append;
  return { ...config, ...append };
};

const parse = async <T extends z.ZodTypeAny>(zodType: T, p: Promise<AxiosResponse>): Promise<z.infer<T>> => {
  const systemStore = useSystemStore();
  await Promise.resolve(systemStore.pendingPromise);
  const response = await p;
  return errorWrapper(() => zodType.parse(response.status === 204 ? [] : response.data));
};

export const parseGet = <T extends z.ZodTypeAny>(zodType: T, url: string, config?: AxiosRequestConfig) =>
  parse(zodType, useSystemStore().getApiClientInstance().get(url, mergeConfig(config)));

export const parsePost = <T extends z.ZodTypeAny>(zodType: T, url: string, payload?, config?: AxiosRequestConfig) =>
  parse(zodType, useSystemStore().getApiClientInstance().post(url, payload, mergeConfig(config)));

export const parsePatch = <T extends z.ZodTypeAny>(zodType: T, url: string, payload?, config?: AxiosRequestConfig) =>
  parse(zodType, useSystemStore().getApiClientInstance().patch(url, payload, mergeConfig(config)));

export const parsePut = <T extends z.ZodTypeAny>(zodType: T, url: string, payload?, config?: AxiosRequestConfig) =>
  parse(zodType, useSystemStore().getApiClientInstance().put(url, payload, mergeConfig(config)));

export const voidDelete = (url: string, config?: AxiosRequestConfig) => useSystemStore().getApiClientInstance().delete(url, config);

export const parseDelete = <T extends z.ZodTypeAny>(zodType: T, url: string, config?: AxiosRequestConfig) =>
  parse(zodType, useSystemStore().getApiClientInstance().delete(url, mergeConfig(config)));

const multipartConfig = (config?: AxiosRequestConfig, updateCallback?: (p: number) => void): AxiosRequestConfig => {
  if (updateCallback) updateCallback(0);
  return {
    ...mergeConfig(config),
    onUploadProgress: ({ loaded, total = 1 }) => {
      if (updateCallback) updateCallback(Math.round((loaded * 100) / total));
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
};

export const parsePostMultipart = <T extends z.ZodTypeAny>(
  zodType: T,
  url: string,
  payload: FormData | Record<string, unknown>,
  updateCallback?: (p: number) => void,
  config?: AxiosRequestConfig
) => {
  return parse(zodType, useSystemStore().getApiClientInstance().postForm(url, payload, multipartConfig(config, updateCallback)));
};

export const parsePutMultipart = <T extends z.ZodTypeAny>(
  zodType: T,
  url: string,
  payload: FormData | Record<string, unknown>,
  updateCallback?: (p: number) => void,
  config?: AxiosRequestConfig
) => {
  return parse(zodType, useSystemStore().getApiClientInstance().putForm(url, payload, multipartConfig(config, updateCallback)));
};
