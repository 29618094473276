import routes from '@routes';
import { parseRoutes } from '@/utils/parseRoutes';
import { hasRole } from '@/utils/roles';
import type { Component } from 'vue';
import type { RouteMeta } from 'vue-router';

export type NavigationItem = {
  name: string;
  meta?: RouteMeta;
  subNavigationItems: NavigationItem[];
  hasSubNavigationItems: boolean;
  icon?: Component;
};

export const parsed = parseRoutes(routes, hasRole);
export const parents = parsed.parents;
export const flatRoutes = parsed.flatRoutes;
export const metas = parsed.metas;
export const hasAllRoles = parsed.hasAllRoles;
export const navigationItems = parsed.navigationItems;
export const visibleNavigationItems = parsed.visibleNavigationItems;
export const getAreaOfRoute = parsed.getAreaOfRoute;
export const visibleUserNavigationItems = parsed.visibleUserNavigationItems;
