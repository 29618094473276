import { useRoute, useRouter } from 'vue-router';
import { DEMO, SETTINGS } from '@route-types';
import { partition } from 'lodash-es';
import type { NavigationItem } from '@/utils/navigation';
import { getAreaOfRoute, metas, visibleNavigationItems } from '@/utils/navigation';

export default function useNavigation() {
  const route = useRoute();
  const router = useRouter();

  const activeName = computed<string | undefined>(() => getAreaOfRoute(route));

  const activePath = computed<string[] | undefined>(() => (route.name ? metas[route.name].path : undefined));

  const currentNavigationItems = computed<NavigationItem[]>(
    () => visibleNavigationItems.value.find(item => item.name === activeName.value)?.subNavigationItems || []
  );

  const navItemsPrimaryFlatItems = computed<NavigationItem[]>(() => visibleNavigationItems.value.flatMap(item => item.subNavigationItems));

  const subActive = computed<string | undefined>(
    () => (route.name && (metas[route.name]?.hasProjectScope ? metas[route.name]?.path?.[2] : metas[route.name]?.path?.[1])) || undefined
  );

  const getRoute = routeName => {
    return router.getRoutes().find(r => r.name === routeName);
  };

  const navItems = computed(() => {
    // eslint-disable-next-line prefer-const
    let [right, left] = partition(visibleNavigationItems.value, { name: SETTINGS });
    left = left.filter(i => i.name !== DEMO);
    return { left, right };
  });

  return {
    activeName,
    activePath,
    visibleNavigationItems,
    currentNavigationItems,
    subActive,
    getRoute,
    navItemsPrimaryFlat: toRef(() => navItemsPrimaryFlatItems.value.filter(i => i.meta?.parent !== DEMO)),
    navItemsPrimary: toRef(() => navItems.value.left),
    navItemsSecondary: toRef(() => navItems.value.right),
  };
}
