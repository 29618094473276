<template>
  <div class="avatar regular-sm" :class="{ dark: isDark }">
    {{ initials }}
  </div>
</template>

<script setup lang="ts">
import { useDark } from '@vueuse/core';

const isDark = useDark();

const props = defineProps<{ name: string }>();

const initials = computed(() =>
  props.name
    .trim()
    .split(/[-\s]/)
    .map(word => (word[0] ? word[0].toUpperCase() : ''))
    .slice(0, 2)
    .join('')
);
</script>

<style scoped lang="scss">
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 24px;
  height: 24px;

  border-radius: 12px;
  background-color: var(--color-primary);

  line-height: 0;
  text-align: center;
  color: var(--color-white);

  &.dark {
    color: var(--color-background-dark);
  }
}
</style>
