import { z } from 'zod';
import { parseDelete, parseGet, parsePatch, parsePost, parsePut } from '@/utils/apiClient';
import { insertPlaceholders } from '@/utils/url';
import { path } from '@/modules/settings/models/path';
import type { TagGroupsOrder } from '@/modules/settings/models/ProjectData';
import { TagGroup, TagGroups } from '@/modules/settings/models/ProjectData';

export const addTagGroupToProject = (id: string, payload: TagGroup) => parsePost(z.any(), insertPlaceholders(path.tagGroups, { id }), payload);

export const updateTagGroupInProject = (id: string, payload: TagGroup) =>
  parsePut(TagGroup, insertPlaceholders(path.tagGroup, { id, tagGroupId: payload.id }), payload);

export const removeTagGroupFromProject = (id: string, tagGroupId: string) => parseDelete(z.any(), insertPlaceholders(path.tagGroup, { id, tagGroupId }));

export const getTagGroupsForProject = (id: string) => parseGet(TagGroups, insertPlaceholders(path.tagGroups, { id }));

export const reorderTagGroupsInProject = (id: string, order: TagGroupsOrder) => parsePatch(z.any(), insertPlaceholders(path.tagGroupsReorder, { id }), order);
