<template>
  <section v-if="isVisible" id="breadcrumb" :key="$route.name ?? ''">
    <span class="sr-only">Breadcrumb:</span>
    <ol class="breadcrumb">
      <component
        :is="getComponent(routeName) ?? Transparent"
        v-bind="getComponentProps(routeName)"
        v-for="(routeName, idx) in items"
        :key="routeName"
        v-memo="[routeName, JSON.stringify(getComponentProps(routeName))]"
        :route-to="isLinkDisabled(routeName, idx) ? undefined : { name: routeName, query: $route.query }"
        :route-name
      >
        <li>
          <span v-if="isLinkDisabled(routeName, idx)" v-html="$to(routeName, 'navigation.', getComponentProps(routeName))" />
          <RouterLink v-else :to="{ name: routeName, query: $route.query }">
            <span v-html="$to(routeName, 'navigation.', getComponentProps(routeName))" />
          </RouterLink>
        </li>
      </component>
      <component
        :is="getComponent($route.name) ?? Transparent"
        :key="'active_' + String($route.name)"
        v-bind="getComponentProps($route.name)"
        is-current
        :route-name="$route.name"
      >
        <li v-show="cockpitHasCustomHeadline || isHeadlineHidden" class="normal">
          <span v-show="isHeadlineHidden" id="currentBreadcrumb" class="ellipsis" />
        </li>
      </component>
    </ol>
  </section>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useWindowScroll } from '@vueuse/core';
import { pick } from 'lodash-es';
import Transparent from '@/components/Transparent.vue';
import { cockpitHasCustomHeadline, registerBreadcrumb } from '@/composables/useLayout';
import useNavigation from '@/composables/useNavigation';
import { flatRoutes, parents } from '@/utils/navigation';

const route = useRoute();

const { y } = useWindowScroll();
const { getRoute } = useNavigation();
const isHeadlineHidden = computed(() => y.value > 30);

const items = computed(() => {
  let { name } = route;
  const names: string[] = [];
  while (name) {
    name = parents[name];
    if (name) names.push(name);
  }
  names.reverse();

  return names.filter(i => !getRoute(i)?.meta.isBreadcrumbHidden);
});

const isVisible = computed<boolean>(() => {
  return items.value.length > 0;
});

registerBreadcrumb(isVisible);

const getComponent = routeName => flatRoutes[routeName]?.breadcrumbComponent;

const getComponentProps = routeName => {
  const r = getRoute(routeName);
  const f = r?.props.default;
  const p = typeof f === 'function' ? f(route) : route.params;
  if (r?.meta?.breadcrumbProps) return pick(p, r.meta.breadcrumbProps);
  return p;
};

const isLinkDisabled = (routeName, idx) => {
  const r = getRoute(routeName);
  return (
    (r?.meta?.isTopNavigation && r.children?.length) || r?.redirect || (r?.meta?.isRedirect && idx + 1 === items.value.length) || r?.meta?.isBreadcrumbDisabled
  );
};
</script>

<style scoped lang="scss">
@use '@/assets/theme/variables';
@use '@/assets/theme/typo';

#breadcrumb {
  display: flex;
  align-items: flex-start;
  line-height: normal;
  color: var(--color-background-dark);
  width: 100%;
  height: variables.$breadcrumb-height;
  padding-top: calc(variables.$content-padding * 1);
  margin-left: calc(variables.$content-padding * -1);
  padding-left: calc(variables.$content-padding * 1);

  position: fixed;
  top: variables.$topbar-height;
  z-index: 5;
  background: var(--color-background);
}

:deep(.breadcrumb) {
  display: flex;
  margin: 0;
  padding: 0;

  > div,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    font-size: 11px;
    font-family: variables.$font-regular;
    font-weight: 400;
    color: var(--color-background-dark);

    > strong {
      color: var(--color-background-dark);
    }

    > a,
    > strong {
      font-size: 11px;
    }

    > a:hover {
      color: var(--color-primary);
    }

    &.normal {
      font-family: variables.$font-regular;
      color: var(--color-background-dark);

      #currentBreadcrumb {
        &:empty {
          display: none;
        }
      }
    }

    + :before {
      margin: 0 6px;
      color: var(--el-text-color-disabled);
      font-weight: 500;
      font-family: variables.$font-bold;
      content: '';
    }
  }
}

:deep(.btn.tertiary.back) {
  @extend .bold-sm;
  gap: 0;
  padding: 0 16px 0 0;
  height: 13px;

  .icon-back {
    width: 13px;
    height: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
