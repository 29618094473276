import { z } from 'zod';
import type { components } from '@/@types/projects';

export const ProjectInfo = z.object({
  projectId: z.string(),
  projectName: z.string(),
  owner: z.string(),
  description: z.string().optional(),
}) satisfies z.ZodType<components['schemas']['ProjectInfo']>;
export type ProjectInfo = z.infer<typeof ProjectInfo>;

export const ZodTagGroupTypeEnum = z.enum(['STATIC', 'DYNAMIC']);
export const TagGroupTypeEnum = ZodTagGroupTypeEnum.enum;
export type TagGroupTypeEnum = z.infer<typeof ZodTagGroupTypeEnum>;

export const TagGroup = z.object({
  id: z.string(),
  name: z.string(),
  type: ZodTagGroupTypeEnum,
  pattern: z.string(),
  tags: z.string().array(),
  index: z.number(),
}) satisfies z.ZodType<components['schemas']['TagGroup']>;
export type TagGroup = z.infer<typeof TagGroup>;

export const TagGroups = z.object({
  tagGroups: z.array(TagGroup).optional(),
}) satisfies z.ZodType<components['schemas']['TagGroups']>;
export type TagGroups = z.infer<typeof TagGroups>;

export const TagGroupsOrder = z.object({
  order: z.record(z.string(), z.number()),
}) satisfies z.ZodType<components['schemas']['TagGroupsOrder']>;
export type TagGroupsOrder = z.infer<typeof TagGroupsOrder>;

export const XrayIntegration = z.object({
  jiraBaseUrl: z.string().optional(),
  jiraApiKey: z.string().optional(),
  jiraUsername: z.string().optional(),
  xrayEnabled: z.boolean(),
  xrayProjectKey: z.string().optional(),
}) satisfies z.ZodType<components['schemas']['XrayIntegration']>;
export type XrayIntegration = z.infer<typeof XrayIntegration>;

export const ProjectData = z.object({
  projectInfo: ProjectInfo,
  tagGroups: TagGroups.optional(),
  xrayIntegration: XrayIntegration.optional(),
}) satisfies z.ZodType<components['schemas']['ProjectData']>;
export type ProjectData = z.infer<typeof ProjectData>;
