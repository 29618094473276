export const umlautTranslator = input =>
  input
    .replace(/[ÂÀÅÃ]/g, 'A')
    .replace(/[âàåã]/g, 'a')
    .replace(/Ä/g, 'AE')
    .replace(/ä/g, 'ae')
    .replace(/Ç/g, 'C')
    .replace(/ç/g, 'c')
    .replace(/[ÉÊÈË]/g, 'E')
    .replace(/[éêèë]/g, 'e')
    .replace(/[ÓÔÒÕØ]/g, 'O')
    .replace(/[óôòõ]/g, 'o')
    .replace(/Ö/g, 'OE')
    .replace(/ö/g, 'oe')
    .replace(/Š/g, 'S')
    .replace(/š/g, 's')
    .replace(/ß/g, 'ss')
    .replace(/[ÚÛÙ]/g, 'U')
    .replace(/[úûù]/g, 'u')
    .replace(/Ü/g, 'UE')
    .replace(/ü/g, 'ue')
    .replace(/[ÝŸ]/g, 'Y')
    .replace(/[ýÿ]/g, 'y')
    .replace(/Ž/g, 'Z')
    .replace(/ž/, 'z');

/** strip html and encodings (e.g. "<span lang="en"><b>a11y</b> &amp; co</span>" -> "a11y & co") */
export const stripMarkup = input => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = input;
  return tmp.textContent || tmp.innerText || '';
};

export const simplify = input =>
  umlautTranslator(stripMarkup(input))
    .toLowerCase()
    .replace(/[^a-z0-9 _-]+/g, '')
    .replace(/[ _-]+/g, '-')
    .trim();

/** Prepend all occurrences of numbers with '0' up to a length of 12, so lexial sorting will result in correctly numbered items */
export const normalizeNumbers = (input: string) =>
  input
    .split(/(?<=[0-9])(?![0-9])|(?<![0-9])(?=[0-9])/)
    .map(p => (p?.[0]?.match(/[0-9]/) ? p.padStart(12, '0') : p))
    .join('');

export const htmlEscape = str =>
  str.replace(/&/g, '&amp').replace(/'/g, '&apos').replace(/"/g, '&quot').replace(/>/g, '&gt').replace(/</g, '&lt').replace(/\//g, '/');

export const toRegex = (input?: string) => {
  if (!input) return undefined;
  try {
    const arr = input.match(/^(?<separator>[/@:|])(?<pattern>.+)\k<separator>(?<flags>[dgimsuvy]*)$/);
    if (!arr?.groups) return new RegExp(input, 'i');
    return new RegExp(arr?.groups['pattern'], arr?.groups['flags']);
  } catch {
    return undefined;
  }
};

export const nextUnusedName = (name: string, names?: string[]) => {
  let suffix: string | number = '';
  while (names?.includes(name + suffix)) {
    suffix = typeof suffix === 'string' ? 2 : suffix + 1;
  }
  return name + suffix;
};

export const uriBase64Encode = (input: string) => {
  return btoa(input).replace(/\+/g, '-').replace(/\//g, '_');
};

export const uriBase64Decode = (input: string) => {
  return atob(input.replace(/-/g, '+').replace(/_/g, '/'));
};
