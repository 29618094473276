import { createApp } from 'vue';
import VueNextProgressbar from '@jambonn/vue-next-progressbar';
import { errorHandler } from '@/errorHandler';
import { i18n, i18nPlugin, initI18n } from '@/plugins/vue-i18n';
import { i18nUtils } from '@/plugins/vue-i18n-utils';
import { mockApi } from '@/stores/storage';
import 'modern-normalize/modern-normalize.css';
import 'element-plus/theme-chalk/src/base.scss';
import 'element-plus/theme-chalk/src/button.scss';
import 'element-plus/theme-chalk/src/overlay.scss';
import 'element-plus/theme-chalk/src/message-box.scss';
import 'element-plus/theme-chalk/src/message.scss';
import '@/assets/theme/styles.scss';
import App from './App.vue';
import installDirectives from './directives';
import router from './router';
import { initStores, store } from './stores';
// TODO: simplify this once the PR (https://github.com/total-typescript/ts-reset/pull/56) is done:
// import '@total-typescript/ts-reset/is-array'; // is overwritten in global.d.ts
import '@total-typescript/ts-reset/fetch';
import '@total-typescript/ts-reset/filter-boolean';
import '@total-typescript/ts-reset/json-parse';
import '@total-typescript/ts-reset/array-includes';
import '@total-typescript/ts-reset/set-has';
import '@total-typescript/ts-reset/map-has';
import '@total-typescript/ts-reset/array-index-of';

const app = createApp(App);
app.use(store).use(router).use(i18nPlugin).use(i18nUtils, { i18n }).use(VueNextProgressbar, { router: false });
installDirectives(app);

app.config.errorHandler = errorHandler;

(async () => {
  if (import.meta.env.DEV || import.meta.env.MODE === 'msw') {
    const { msw } = await import('./mocks/mswBrowser');
    if (mockApi.value) {
      await msw.start();
    }
  }

  initStores();

  try {
    await Promise.all([initI18n(), router.isReady()]);
  } catch (error) {
    errorHandler(error);
  } finally {
    app.mount('#exact-app');
  }
})();
