<template>
  <aside v-if="isLoggedIn" class="sidebar" :class="{ expanded: isSidebarExpanded }">
    <nav>
      <ProjectSelector />

      <div class="divider" />

      <SidebarLinkList :items="navItemsPrimaryFlat" />

      <div class="divider" />

      <SidebarLinkList :items="navItemsSecondary" />

      <div class="divider" style="margin-top: auto" />

      <Btn
        only
        small
        :title="isSidebarExpanded ? $t('navigation.collapse') : $t('navigation.expand')"
        placement="right"
        class="focus-shadow-inset"
        @click="isSidebarExpanded = !isSidebarExpanded"
      >
        <ArrowExpand :expanded="isSidebarExpanded" />
      </Btn>
    </nav>
  </aside>
  <div v-else class="sidebar" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import Btn from '@/components/Btn.vue';
import ProjectSelector from '@/components/ProjectSelector.vue';
import ArrowExpand from '@/components/UI/Icons/ArrowExpand.vue';
import { isSidebarExpanded } from '@/composables/useLayout';
import useNavigation from '@/composables/useNavigation';
import { useAuthStore } from '@/stores/auth';
import SidebarLinkList from './SidebarLinkList.vue';

const { navItemsPrimaryFlat, navItemsSecondary } = useNavigation();

const authStore = useAuthStore();
const { isLoggedIn } = storeToRefs(authStore);
</script>

<style lang="scss" scoped>
@use '@/assets/theme/variables';

.sidebar {
  position: fixed;
  z-index: 6;

  width: variables.$sidebar-mobile-width;
  height: calc(100% - variables.$topbar-height);
  background-color: var(--color-background);

  .spacer {
    width: variables.$sidebar-mobile-width;
  }

  nav {
    display: flex;
    flex-direction: column;

    width: variables.$sidebar-mobile-width;
    height: 100%;
    overflow-y: auto;
    background-color: var(--color-background);

    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    .divider {
      height: 1px;
      align-self: stretch;
      background: var(--Primary-hover-light, #e2deff);
    }

    :deep(.btn:not(.unstyled).only-icon.only) {
      align-self: stretch;
      height: 44px;
      width: 100%;
      padding: 10px;

      display: flex;
      justify-content: center;
      align-items: center;

      .icon-arrow-expand {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.expanded {
    width: variables.$sidebar-width;

    .spacer {
      width: variables.$sidebar-width;
    }

    nav {
      width: variables.$sidebar-width;

      :deep(.btn:not(.unstyled).only-icon.only) {
        justify-content: flex-end;
      }
    }
  }
}
</style>
