import { i18n } from '@/plugins/vue-i18n';
import type { ProjectData, TagGroup } from '@/modules/settings/models/ProjectData';
import {
  addTagGroupToProject,
  getTagGroupsForProject,
  removeTagGroupFromProject,
  reorderTagGroupsInProject,
  updateTagGroupInProject,
} from '@/modules/settings/service/tagGroups';

export const actionsTagGroups = (project: ProjectData) => ({
  getTagGroups: async () => {
    const { projectId } = project.projectInfo;
    const tagGroups = await getTagGroupsForProject(projectId);
    project.tagGroups = tagGroups;
  },

  reorderTagGroups: async (from: number, to: number) => {
    const { projectId } = project.projectInfo;
    if (!project.tagGroups?.tagGroups) throw new Error('No Tag Groups');

    const el = project.tagGroups.tagGroups[from];
    project.tagGroups.tagGroups.splice(from, 1);
    project.tagGroups.tagGroups.splice(to, 0, el);

    const order = {
      order: {},
    };
    project.tagGroups.tagGroups.forEach((g, idx) => {
      g.index = idx;
      order.order[g.id] = idx;
    });

    try {
      await reorderTagGroupsInProject(projectId, order);
      ElMessage({ type: 'success', message: i18n.t('settings.tagGroups.message.reorderSuccess') });
    } catch {
      ElMessage({ type: 'error', message: i18n.t('settings.tagGroups.message.reorderFailed') });
    }
  },

  addTagGroup: async (payload: TagGroup) => {
    const { projectId } = project.projectInfo;
    await addTagGroupToProject(projectId, payload);
    project.tagGroups?.tagGroups?.push(payload);
  },

  updateTagGroup: async (payload: TagGroup) => {
    const { projectId } = project.projectInfo;
    const response = await updateTagGroupInProject(projectId, payload);
    if (!project.tagGroups?.tagGroups) throw new Error('No Tag Groups');
    const found = project.tagGroups.tagGroups.findIndex(g => g.id === response.id);
    if (found !== -1) project.tagGroups.tagGroups[found] = response;
  },

  removeTagGroup: async (index: number) => {
    const { projectId } = project.projectInfo;
    if (!project.tagGroups?.tagGroups) throw new Error('No Tag Groups');
    const tagGroupId = project.tagGroups.tagGroups[index].id;
    await removeTagGroupFromProject(projectId, tagGroupId);
    project.tagGroups.tagGroups.splice(index, 1);
  },
});
