export function assert(condition: any, msg = `Expected 'condition' to be truthy`): asserts condition {
  if (!condition) {
    throw Error(msg);
  }
}

export function assertIsDefined<T>(value: T, msg = `Expected 'value' to be defined, but received ${value}`): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw Error(msg);
  }
}

export function assertIsString(value: any): asserts value is string {
  if (typeof value !== 'string') {
    throw Error('Not a string!');
  }
}

export function assertIsFilled<T>(value: Partial<T>): asserts value is T {
  if (typeof value !== 'object') {
    throw Error('Not an object!');
  }
}

export function isObject(value: unknown): value is Record<string, unknown> {
  return (typeof value === 'object' || typeof value === 'function') && value !== null;
}

export function hasOwnProperty<X, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return isObject(obj) && Object.hasOwnProperty.call(obj, prop);
}

export function isDefined<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}
