import type { ProjectData, XrayIntegration } from '@/modules/settings/models/ProjectData';
import { getXrayIntegration, updateXrayIntegration } from '@/modules/settings/service/xrayIntegration';

export const actionsXrayIntegration = (project: ProjectData) => ({
  getXrayIntegration: async () => {
    const { projectId } = project.projectInfo;
    project.xrayIntegration = await getXrayIntegration(projectId);
  },

  updateXrayIntegration: async (payload: XrayIntegration) => {
    const { projectId } = project.projectInfo;
    project.xrayIntegration = await updateXrayIntegration(projectId, payload);
  },
});
