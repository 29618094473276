<template>
  <svg class="icon-user" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8235 11.9412C14.2925 11.9412 16.2941 9.93963 16.2941 7.47059C16.2941 5.00155 14.2925 3 11.8235 3C9.35446 3 7.35291 5.00155 7.35291 7.47059C7.35291 9.93963 9.35446 11.9412 11.8235 11.9412Z"
      stroke="currentColor"
      stroke-miterlimit="50"
    />
    <path
      d="M19.6471 21.9998C19.6471 17.6788 16.1443 14.1763 11.8237 14.1763C7.50272 14.1763 4 17.6791 4 21.9998"
      stroke="currentColor"
      stroke-miterlimit="50"
    />
  </svg>
</template>
