<template>
  <svg class="icon-mode-light" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4811_61950)">
      <path
        d="M12.2551 17.745C15.5688 17.745 18.2551 15.0587 18.2551 11.745C18.2551 8.43129 15.5688 5.745 12.2551 5.745C8.94142 5.745 6.25513 8.43129 6.25513 11.745C6.25513 15.0587 8.94142 17.745 12.2551 17.745Z"
        stroke="currentColor"
        stroke-miterlimit="133.33"
        stroke-linecap="square"
      />
      <path d="M2.80157 11.7452L1.00019 11.7452" stroke="currentColor" stroke-miterlimit="133.33" stroke-linecap="square" />
      <path d="M23.0567 11.7452L21.2553 11.7452" stroke="currentColor" stroke-miterlimit="133.33" stroke-linecap="square" />
      <path d="M12.2554 23V21.1986" stroke="currentColor" stroke-miterlimit="133.33" stroke-linecap="square" />
      <path d="M12.2554 2.54637V0.744995" stroke="currentColor" stroke-miterlimit="133.33" stroke-linecap="square" />
      <path d="M5.52881 5.01888L4.25504 3.74512" stroke="currentColor" stroke-miterlimit="133.33" stroke-linecap="square" />
      <path d="M19.8516 19.3415L18.5778 18.0677" stroke="currentColor" stroke-miterlimit="133.33" stroke-linecap="square" />
      <path d="M4.25529 19.6622L5.52905 18.3884" stroke="currentColor" stroke-miterlimit="133.33" stroke-linecap="square" />
      <path d="M18.7182 5.1993L19.9919 3.92554" stroke="currentColor" stroke-miterlimit="133.33" stroke-linecap="square" />
    </g>
    <defs>
      <clipPath id="clip0_4811_61950">
        <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0 24)" />
      </clipPath>
    </defs>
  </svg>
</template>
