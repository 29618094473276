export const highlight: { approvals?: number; batches?: number; table?: number | string } = {};

const highlightDirective = app => {
  app.directive('highlight', {
    beforeMount(el, context) {
      check(el, context);
    },
    updated(el, context) {
      check(el, context);
    },
  });
};

const check = (el, { value, arg, modifiers }) => {
  if (highlight[arg] !== undefined) {
    const key = Object.keys(modifiers)?.[0] || 'id';
    if (value[key] === highlight[arg]) {
      highlight[arg] = undefined;
      el.classList.add('highlight');
      setTimeout(() => {
        if (!el) return;
        el.classList.remove('highlight');
      }, 10000);
    }
  }
};
export default highlightDirective;
