import { mergeWith } from 'lodash-es';
import type { NavigationItem } from '@/utils/navigation';
import type { RouteLocationNormalized, RouteMap, RouteMeta, RouteRecordRaw } from 'vue-router';
import type { RoleEnum } from '@/models/user/RoleEnum';

export type RouteRecordRawExtended = RouteRecordRaw & { icon?: Component; breadcrumbComponent?: Component };

export const parseRoutes = (routes: RouteRecordRaw[], hasRole: (role: RoleEnum) => boolean) => {
  const parseParentsRecursive = (items: RouteRecordRaw[], parents: Record<keyof RouteMap, string | undefined> = {}, parent?: keyof RouteMap) => {
    items.forEach(r => {
      if (r.children) parents = parseParentsRecursive(r.children, parents, r.name);
      const p = r?.meta?.parent || parent;
      if (r.name) parents[r.name] = p?.toString();
    });
    return parents;
  };

  /**
   * Maps name to parent. If no parent is present, it will hold `undefined` instead
   *
   * @example
   *   { A: undefined, childOfA: 'A', anotherChildOfA: 'A', nestedChildOfChildOfA: 'childOfA' }
   */
  const parents = parseParentsRecursive(routes);

  const parseFlatRoutesRecursive = (routes: RouteRecordRaw[]): Record<keyof RouteMap, RouteRecordRawExtended> => {
    const flatByName: Record<keyof RouteMap, RouteRecordRawExtended> = {};
    routes.forEach(r => {
      if (r.children) Object.assign(flatByName, parseFlatRoutesRecursive(r.children));
      if (r.name)
        flatByName[r.name] = {
          ...r,
          icon: r.meta?.icon && markRaw(defineAsyncComponent(r.meta.icon)),
          breadcrumbComponent: r.meta?.breadcrumbComponent && markRaw(defineAsyncComponent(r.meta.breadcrumbComponent)),
        };
    });
    return flatByName;
  };

  /**
   * Flat object containing all routes mapped by name
   *
   * @example
   *   { A: RouteRecordRaw, childOfA: RouteRecordRaw, anotherChildOfA: RouteRecordRaw, nestedChildOfChildOfA: RouteRecordRaw }
   */
  const flatRoutes = parseFlatRoutesRecursive(routes);

  type Metas = Record<
    keyof RouteMap,
    RouteMeta & {
      /** All routes from parent to children */
      path: string[];
      /** Name of the root Route */
      root: string;
      /** Indicates whether an actual redirect or meta "isRedirect" is present */
      hasRedirect: boolean;
      hasProjectScope: boolean;
    }
  >;
  const parseBubbleMeta = (): Metas => {
    const metas: Metas = {};
    Object.keys(flatRoutes).forEach((key: keyof RouteMap) => {
      const path: string[] = [];
      let name: keyof RouteMap | undefined = key;
      while (name && parents[name]) {
        path.unshift(String(name));
        name = parents[name];
      }
      if (name) {
        path.unshift(String(name));
      }
      const meta = {
        path,
        root: String(path[0]),
        hasRedirect: !!flatRoutes[key].redirect || !!flatRoutes[key].meta?.isRedirect,
        hasProjectScope: false, // might be overwritten by actual meta
      };
      path.forEach(n => {
        mergeWith(meta, { ...flatRoutes[n].meta }, (objValue, srcValue) => (Array.isArray(objValue) ? objValue.concat(srcValue) : srcValue));
      });
      metas[key] = meta;
    });

    const mergeRolesToChildren = (routes: RouteRecordRaw[], parentRoles: RouteMeta['roles'] = []): void => {
      routes.forEach(i => {
        let roles: RouteMeta['roles'] = parentRoles;
        if (i.name) metas[i.name].roles = roles.concat(metas[i.name]?.roles || []);
        if (i.children) {
          if (i.meta?.roles) roles = roles.concat(i.meta.roles);
          mergeRolesToChildren(i.children, roles);
        }
      });
    };

    mergeRolesToChildren(routes);

    return metas;
  };

  /**
   * Metas are merged recursively in a hierarchic order as shown in the breadcrumb.
   *
   * Additionally, all parent roles get concatenated
   *
   * @see Metas
   */
  const metas = parseBubbleMeta();

  const findSubNavigation = (parent: RouteRecordRaw): NavigationItem[] =>
    Object.values(flatRoutes)
      .filter(r => r.name && r.meta?.isSubNavigation && metas[r.name]?.parent === parent.name)
      .map(r => {
        const subNavigationItems = findSubNavigation(r);
        return {
          name: String(r.name),
          meta: r.meta,
          subNavigationItems,
          hasSubNavigationItems: subNavigationItems.length > 0,
          icon: r.meta?.icon && markRaw(defineAsyncComponent(r.meta.icon)),
        };
      });

  const parseNavigationItems = (routes: RouteRecordRaw[]): NavigationItem[] => {
    let arr: NavigationItem[] = [];
    routes.forEach(r => {
      if (r.meta?.isTopNavigation) {
        const subNavigationItems = findSubNavigation(r);
        arr.push({
          name: String(r.name),
          meta: r.meta,
          subNavigationItems,
          hasSubNavigationItems: subNavigationItems.length > 0,
          icon: r.meta?.icon && markRaw(defineAsyncComponent(r.meta.icon)),
        });
      }
      if (r.children) arr = arr.concat(parseNavigationItems(r.children));
    });
    return arr;
  };

  /**
   * Array of NavigationItems which have "isTopNavigation" meta - containing of:
   * - name: name of the route
   * - meta: its meta (not merged with parents)
   * - subNavigationItems: recursive array of NavigationItems (which have "isSubNavigation" meta) with name as parent
   * - hasSubNavigationItems: boolean
   */
  const navigationItems = parseNavigationItems(routes);

  const hasAllRoles = (roles?: RouteMeta['roles']) => !roles?.some(r => (typeof r === 'function' ? !r() : !hasRole(r)));

  /** Remove navigation items with insufficient roles, or when all first-level children have insufficient roles */
  const filterDeep = (items: NavigationItem[]): NavigationItem[] =>
    items
      .map(i => ({ ...i, subNavigationItems: filterDeep(i.subNavigationItems) }))
      .filter(i => !i.name || hasAllRoles(metas[i.name]?.roles))
      .filter(i => !i.hasSubNavigationItems || i.subNavigationItems.length > 0);

  /** Filtered array of NavigationItems based on current roles */
  const visibleNavigationItems = computed(() =>
    filterDeep(
      navigationItems
        .filter(i => !i.name || !metas[i.name]?.roles || hasAllRoles(metas[i.name].roles))
        .filter(i => {
          const namesOfChildren = Object.keys(parents).filter(p => parents[p] === i.name);
          if (namesOfChildren.length === 0) return true;
          return namesOfChildren.map(name => !metas[name].roles || hasAllRoles(metas[name].roles)).some(v => v); // keep (true) = element has no roles defined OR no roles are not allowed
        })
    )
  );

  /** Gets the root area of a route, based on its hierarchy */
  const getAreaOfRoute = (route: RouteLocationNormalized) => {
    if (!route.name) return undefined;
    return metas[route.name].root;
  };

  const userNavigationItems = routes.filter(r => r.meta?.isUserNavigation);

  const visibleUserNavigationItems = computed(() =>
    userNavigationItems.filter(i => !i.name || !metas[i.name]?.roles || hasAllRoles(metas[i.name].roles)).map(r => String(r.name))
  );

  return {
    parents,
    flatRoutes,
    metas,
    navigationItems,
    hasAllRoles,
    visibleNavigationItems,
    getAreaOfRoute,
    visibleUserNavigationItems,
  };
};
