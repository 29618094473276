import * as types from '@route-types';
import { RoleEnum } from '@/models/user/RoleEnum';
import type { RouteRecordRaw } from 'vue-router';

const Plain = defineAsyncComponent(() => import('@/layouts/Plain.vue'));

const routes: RouteRecordRaw[] = [
  {
    path: '/demo',
    name: types.DEMO,
    component: () => import('@/modules/demo/views/Overview.vue'),
    meta: {
      isBreadcrumbDisabled: true,
      isRedirect: true,
      isTopNavigation: true,
      isSearchDisabled: true,
    },
    children: [
      {
        path: 'layout',
        name: types.DEMO_LAYOUT,
        component: () => import('@/modules/demo/views/DemoLayout.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
      {
        path: 'button',
        name: types.DEMO_BUTTON,
        component: () => import('@/modules/demo/views/DemoButton.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
      {
        path: 'utils',
        name: types.DEMO_UTILS,
        component: () => import('@/modules/demo/views/DemoUtils.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
      {
        path: 'lazystate',
        name: types.DEMO_LAZYSTATE,
        component: () => import('@/modules/demo/views/DemoLazyState.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
      {
        path: 'table',
        name: types.DEMO_TABLE,
        component: () => import('@/modules/demo/views/DemoTable.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
      {
        path: 'buttonbar',
        name: types.DEMO_FORM_BUTTONBAR,
        component: () => import('@/modules/demo/views/Form/DemoButtonBar.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
      {
        path: 'use',
        name: types.DEMO_FORM_USEFORM,
        component: () => import('@/modules/demo/views/Form/DemoFormUse.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
      {
        path: 'helpers',
        name: types.DEMO_FORM_HELPERS,
        component: () => import('@/modules/demo/views/Form/DemoFormHelpers.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
      {
        path: 'upload',
        name: types.DEMO_FORM_UPLOAD,
        component: () => import('@/modules/demo/views/Form/DemoFormUpload.vue'),
        meta: {
          parent: types.DEMO,
          isSubNavigation: true,
        },
      },
    ],
  },
  {
    path: '/error',
    name: types.ERROR,
    component: () => import('@/views/Error.vue'),
    meta: {
      isSearchDisabled: true,
      icon: () => import('@/components/UI/Icons/Error.vue'),
    },
  },
  {
    path: '/',
    name: types.DASHBOARD,
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      icon: () => import('@/components/UI/Icons/Garage.vue'),
    },
  },
  {
    path: '/login',
    name: types.LOGIN,
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: Plain,
      isSearchDisabled: true,
    },
  },
  {
    path: '/mock/login',
    name: types.LOGIN_MOCK,
    component: () => import('@/views/LoginMock.vue'),
    meta: {
      layout: Plain,
      isSearchDisabled: true,
    },
  },
  {
    path: '/user',
    name: types.USER,
    component: () => import('@/views/Empty.vue'),
  },
  {
    path: '/profile',
    name: types.PROFILE,
    component: () => import('@/views/Empty.vue'),
    meta: {
      isUserNavigation: false,
    },
  },
  {
    path: '/new-project',
    name: types.ADD_NEW_PROJECT,
    component: () => import('@/modules/projects/views/AddNewProject.vue'),
    meta: {
      roles: [RoleEnum.PROJECT_ADD],
    },
  },
  {
    path: '/logout',
    name: types.LOGOUT,
    component: () => import('@/views/Logout.vue'),
    meta: {
      isUserNavigation: true,
      layout: Plain,
    },
  },

  {
    path: '/platform/:projectId?',
    name: types.PLATFORM,
    component: () => import('@/components/WrapProjectScope.vue'),
    props: true,
    meta: {
      breadcrumbComponent: () => import('@/components/ProjectScopeBreadcrumb.vue'),
      isBreadcrumbHidden: true,
      breadcrumbProps: ['projectId'],
      hasProjectScope: true,
      icon: () => import('@/components/UI/Icons/Platform.vue'),
      isTopNavigation: true,
      refreshOnUrlChange: true,
    },
    children: [
      {
        path: 'vvg',
        name: types.PLATFORM_VVG,
        component: () => import('@/modules/platform/views/VehicleOverview.vue'),
        meta: {
          parent: types.PLATFORM,
          isSubNavigation: true,
          roles: [RoleEnum.ACCESS_VVG],
          icon: () => import('@/components/UI/Icons/VirtualVehicles.vue'),
        },
      },
      {
        path: 'vvg/:vin',
        name: types.PLATFORM_DETAILS,
        component: () => import('@/modules/platform/views/VehicleDetails.vue'),
        props: true,
        meta: {
          parent: types.PLATFORM_VVG,
          icon: () => import('@/components/UI/Icons/Car.vue'),
          hasProjectDetails: true,
        },
      },
    ],
  },

  {
    path: '/automation/:projectId?',
    name: types.AUTOMATION,
    component: () => import('@/components/WrapProjectScope.vue'),
    props: true,
    meta: {
      isTopNavigation: true,
      breadcrumbComponent: () => import('@/components/ProjectScopeBreadcrumb.vue'),
      isBreadcrumbHidden: true,
      breadcrumbProps: ['projectId'],
      hasProjectScope: true,
      icon: () => import('@/components/UI/Icons/Automation.vue'),
      refreshOnUrlChange: true,
    },
    children: [
      {
        path: 'test-repository',
        name: types.AUTOMATION_TEST_REPOSITORY,
        component: () => import('@/views/TestRepository.vue'),
        meta: {
          parent: types.AUTOMATION,
          isSubNavigation: true,
          roles: [RoleEnum.ACCESS_TEST_REPOSITORY],
          icon: () => import('@/components/UI/Icons/TestRepository.vue'),
        },
      },
      {
        path: 'test-repository/:id/:description?',
        name: types.AUTOMATION_TEST_REPOSITORY_TESTSET,
        component: () => import('@/views/Testset.vue'),
        props: true,
        meta: {
          parent: types.AUTOMATION_TEST_REPOSITORY,
          refreshOnUrlChange: true,
          icon: () => import('@/components/UI/Icons/TestSet.vue'),
          hasProjectDetails: true,
        },
      },
      {
        path: 'testplan',
        name: types.AUTOMATION_TESTPLAN_LIST,
        component: () => import('@/modules/testplans/views/TestPlanList.vue'),
        props: true,
        meta: {
          parent: types.AUTOMATION,
          isSubNavigation: true,
          roles: [RoleEnum.ACCESS_TEST_PLANS],
          icon: () => import('@/components/UI/Icons/TestPlans.vue'),
        },
      },
      {
        path: 'testplan/:id',
        name: types.AUTOMATION_TESTPLAN_DETAILS,
        component: () => import('@/modules/testplans/views/TestPlanDetails.vue'),
        props: true,
        meta: {
          parent: types.AUTOMATION_TESTPLAN_LIST,
          icon: () => import('@/components/UI/Icons/TestPlan.vue'),
          hasProjectDetails: true,
        },
      },
      {
        path: 'testplan/:id/webhook/documentation',
        name: types.AUTOMATION_TESTPLAN_WEBHOOK_DOC,
        component: () => import('@/modules/testplans/views/TestPlanWebhookDoc.vue'),
        props: true,
        meta: {
          parent: types.AUTOMATION_TESTPLAN_DETAILS,
        },
      },
    ],
  },

  {
    path: '/settings/:projectId?',
    name: types.SETTINGS,
    component: () => import('@/modules/settings/views/Settings.vue'),
    props: true,
    meta: {
      isTopNavigation: true,
      roles: [RoleEnum.ACCESS_SETTINGS],
      icon: () => import('@/components/UI/Icons/Gear.vue'),
      isRedirect: true,
      breadcrumbComponent: () => import('@/components/ProjectScopeBreadcrumb.vue'),
      isBreadcrumbHidden: true,
      breadcrumbProps: ['projectId'],
      hasProjectScope: true,
      refreshOnUrlChange: true,
    },
    children: [
      {
        path: 'project-information',
        name: types.SETTINGS_PROJECT_INFORMATION,
        component: () => import('@/modules/settings/views/SettingsProjectInformation.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_PROJECT_INFORMATION],
          icon: () => import('@/components/UI/Icons/ProjectInfo.vue'),
        },
      },
      {
        path: 'test-repository',
        name: types.SETTINGS_TEST_REPOSITORY,
        component: () => import('@/modules/settings/views/SettingsTestRepository.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_TEST_REPOSITORY],
          icon: () => import('@/components/UI/Icons/TestRepository.vue'),
        },
      },
      {
        path: 'tooling',
        name: types.SETTINGS_TOOLING,
        component: () => import('@/modules/settings/views/SettingsTooling.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_TOOLING],
          icon: () => import('@/components/UI/Icons/Tooling.vue'),
        },
      },
      {
        path: 'secrets',
        name: types.SETTINGS_SECRETS,
        component: () => import('@/modules/settings/views/SettingsSecrets.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_SECRETS],
          icon: () => import('@/components/UI/Icons/Secret.vue'),
        },
      },
      {
        path: 'users',
        name: types.SETTINGS_USERS,
        component: () => import('@/modules/settings/views/SettingsUsers.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_USERS],
          icon: () => import('@/components/UI/Icons/Users.vue'),
        },
      },
      {
        path: 'teams',
        name: types.SETTINGS_TEAMS,
        component: () => import('@/modules/settings/views/SettingsTeams.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_TEAMS],
          icon: () => import('@/components/UI/Icons/Group.vue'),
        },
      },
      {
        path: 'environments',
        name: types.SETTINGS_ENVIRONMENTS,
        component: () => import('@/modules/settings/views/SettingsEnvironment.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_ENVIRONMENTS],
          icon: () => import('@/components/UI/Icons/Environment.vue'),
        },
      },
      {
        path: 'tags',
        name: types.SETTINGS_TAG_GROUPS,
        component: () => import('@/views/SettingsTagGroupsOverview.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_TAG_GROUPS],
          icon: () => import('@/components/UI/Icons/TagGroups.vue'),
        },
      },
      {
        path: 'jira',
        name: types.SETTINGS_JIRA_XRAY,
        component: () => import('@/modules/settings/views/SettingsJiraXray.vue'),
        meta: {
          parent: types.SETTINGS,
          roles: [RoleEnum.ACCESS_SETTINGS_JIRA_XRAY],
          icon: () => import('@/components/UI/Icons/Jira.vue'),
        },
      },
    ],
  },

  {
    path: '/reporting/:projectId?',
    name: types.REPORTING,
    component: () => import('@/components/WrapProjectScope.vue'),
    props: true,
    meta: {
      breadcrumbComponent: () => import('@/components/ProjectScopeBreadcrumb.vue'),
      isBreadcrumbHidden: true,
      breadcrumbProps: ['projectId'],
      hasProjectScope: true,
      icon: () => import('@/components/UI/Icons/Reporting.vue'),
      isTopNavigation: true,
      refreshOnUrlChange: true,
    },
    children: [
      {
        path: 'overview',
        name: types.REPORTING_OVERVIEW,
        component: () => import('@/modules/reporting/views/Overview.vue'),
        meta: {
          parent: types.REPORTING,
          isSubNavigation: true,
          roles: [RoleEnum.ACCESS_REPORTING],
          icon: () => import('@/components/UI/Icons/TestResults.vue'),
        },
      },
      {
        path: ':testExecutionId/:indexSet?/:indexTable?/:indexTest?',
        name: types.REPORTING_DETAILS,
        component: () => import('@/modules/reporting/views/TestReport.vue'),
        props: true,
        meta: {
          parent: types.REPORTING_OVERVIEW,
          icon: () => import('@/components/UI/Icons/Execution.vue'),
          hasProjectDetails: true,
          refreshOnUrlChange: false,
        },
      },
      {
        path: 'bugs',
        name: types.REPORTING_BUGS,
        component: () => import('@/modules/reporting/views/BugsOverview.vue'),
        meta: {
          parent: types.REPORTING,
          isSubNavigation: true,
          roles: [RoleEnum.ACCESS_REPORTING_BUGS],
          icon: () => import('@/components/UI/Icons/Bug.vue'),
        },
      },
      {
        path: 'project-reports',
        name: types.REPORTING_PROJECT_REPORTS,
        component: () => import('@/modules/project-reports/views/ProjectReportsOverview.vue'),
        meta: {
          parent: types.REPORTING,
          isSubNavigation: true,
          roles: [RoleEnum.ACCESS_REPORTING_PROJECT_REPORTS],
          icon: () => import('@/components/UI/Icons/ProjectReports.vue'),
        },
      },
      {
        path: 'project-reports/config/:id?',
        name: types.REPORTING_PROJECT_REPORTS_CONFIG,
        props: true,
        component: () => import('@/modules/project-reports/views/ProjectReportsConfig.vue'),
        meta: {
          parent: types.REPORTING_PROJECT_REPORTS,
          icon: () => import('@/components/UI/Icons/ProjectReports.vue'),
          hasProjectDetails: true,
          refreshOnUrlChange: false,
        },
      },
      {
        path: 'poc',
        name: types.REPORTING_POC,
        component: () => import('@/views/PocReporting.vue'),
        meta: {
          parent: types.REPORTING,
        },
      },
    ],
  },
];

export default routes;
