import { createPinia } from 'pinia';
import { errorHandler } from '@/errorHandler';
import { useSystemStore } from '@/stores/system';
import project from '@/modules/settings/store/project';
import { useAuthStore } from './auth';

export const store = createPinia();

export const initStores = async () => {
  const { init: initSystem } = useSystemStore();
  await initSystem();

  const auth = useAuthStore();
  try {
    if (auth.isLoggedIn) {
      await project.init();
    } else {
      await auth.postAuthResolvers.promise;
      await project.init();
    }
  } catch (error) {
    errorHandler(error);
  }
};
